<template>
  <div v-if="!isApp && isOpen" v-motion-slide-bottom class="opacity-0 md:hidden fixed bottom-0 left-0 right-0 w-full shadow-xl bg-white z-50 rounded-t-md border-2 border-primary/20 ">
    <div class="p-4 pr-1 bg-primary/10 flex items-center justify-center">
      <!-- Logo -->
      <img src="~/assets/images/logo.png" class="size-7" />
      <!-- Copy -->
      <div class="flex-1 ml-4">
        <p class="font-semibold text-sm"><span class="hidden-mini">Baixe o app do </span><span class="hidden-bigger">App </span>respostaCerta</p>
        <p class="text-xs ">
          iOS e Android
        </p>
      </div>

      <!-- Cta button -->
      <a :href="ctaLink">
        <button class="text-sm bg-primary rounded-full p-2 px-6 font-semibold tracking-wider text-white">
          Baixar
        </button>
      </a>

      <!-- Close button -->
      <button class="p-2" @click="isOpen = false">
        <v-icon name="hi-x" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isApp = useIsApp();
const isOpen = ref(true)

const ctaLink = computed(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.includes('android');

  if (isAndroid) {
    return 'https://play.google.com/store/apps/details?id=com.grqtech.respostacerta';
  } else {
    return 'https://apps.apple.com/us/app/respostacerta-questões-enem/id6740682044';
  }
})
</script>

<style scoped>
.hidden-mini {
  @media screen and (max-width: 375px) {
    display: none;
  }
}
.hidden-bigger {
  @media screen and (min-width: 375px) {
    display: none;
  }
}
</style>
